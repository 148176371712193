// Vendor
import classNames from "classnames";
import React from "react";
import { Trans, Translation, withTranslation } from "react-i18next";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  NavItem,
  NavLink,
  UncontrolledDropdown
} from "reactstrap";
import {
  AvFeedback,
  AvField,
  AvGroup,
  AvInput
} from "availity-reactstrap-validation";
import {
  connectCurrentRefinements,
  connectMenu,
  connectPagination,
  connectRange,
  connectRefinementList,
  connectStats,
  Pagination
} from "react-instantsearch-dom";

// App
import logo from "images/gf365-logo.svg";
import EventEmitterClient from "common/EventEmitterClient";
import Auth from "common/Auth";
import TeeTimeOptionButtons from "components/ui/tee-time/TeeTimeOptionButtons";
import {
  getRepeatPasswordValidator,
  EMAIL_VALIDATOR,
  NAME_VALIDATOR,
  PASSWORD_VALIDATOR
} from "common/Validators";
import {
  USER_PROFILE_ROUTE,
  TERMS_OF_SERVICE_ROUTE,
  PRIVACY_POLICY_ROUTE,
  SEARCH_ROUTE,
  USER_BOOKINGS_ROUTE,
  routesMatch
} from "common/RoutesHelper";
import LocaleHelper from "common/LocaleHelper";
import Algolia from "common/Algolia";

export const Address = ({ club }) => {
  return (
    <Translation>
      {t => (
        <address className="my-5">
          <div className="mb-5">
            <h3>{t("phone_number")}</h3>
            <p className="key-figure">{club.phone}</p>
          </div>
          <div>
            <h3>{t("address")}</h3>
            <p className="key-figure">
              {club.address}, {club.country}
            </p>
          </div>
        </address>
      )}
    </Translation>
  );
};

export const AllClubsBtn = ({
  className = "",
  color = "purple",
  shadow = false
}) => (
  <Translation>
    {t => (
      <SearchShortcutButton
        className={className}
        color={color}
        icon="golf-ball"
        shadow={shadow}
        text={t("show_all_golf_courses")}
        link={SEARCH_ROUTE.url()}
      />
    )}
  </Translation>
);

export const AreaRefinement = ({ area }) => {
  return !area ? null : (
    <ConnectedRefinementList attribute="areas" defaultRefinement={[area]} />
  );
};

export const AuthMenuLoggedOut = () => (
  <Translation>
    {t => (
      <>
        <NavItem>
          <Button
            color="link"
            className="nav-link"
            onClick={() => {
              EventEmitterClient.emit("SIGNUP_SHOW");
            }}
          >
            {t("sign_up")}
          </Button>
        </NavItem>
        <NavItem>
          <Button
            color="link"
            className="nav-link"
            onClick={() => {
              EventEmitterClient.emit("LOGIN_SHOW");
            }}
          >
            {t("log_in")}
          </Button>
        </NavItem>
      </>
    )}
  </Translation>
);

export const AuthMenuLoggedIn = withRouter(
  ({ location, iconClass = "text-black-50" }) => {
    const getClassName = route =>
      routesMatch(location.pathname, route.path) ? "active" : "";
    return (
      <Translation>
        {t => (
          <>
            <UncontrolledDropdown
              className="d-none d-sm-inline-block"
              data-cy="user-menu"
            >
              <DropdownToggle
                color="link"
                className={"py-0 px-1 " + iconClass}
                caret={false}
                style={{ fontSize: "1.5rem" }}
              >
                <FontAwesomeIcon icon="user-circle" fixedWidth />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  tag={Link}
                  to={USER_BOOKINGS_ROUTE.url()}
                  className={getClassName(USER_BOOKINGS_ROUTE)}
                >
                  {t("my_bookings")}
                </DropdownItem>
                <DropdownItem
                  tag={Link}
                  to={USER_PROFILE_ROUTE.url()}
                  className={getClassName(USER_PROFILE_ROUTE)}
                >
                  {t("settings")}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={Auth.logout}>
                  {t("log_out")}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>

            <NavItem className="d-sm-none">
              <Link to={USER_BOOKINGS_ROUTE.url()} className="nav-link">
                {t("my_bookings")}
              </Link>
            </NavItem>
            <NavItem className="d-sm-none">
              <Link to={USER_PROFILE_ROUTE.url()} className="nav-link">
                {t("settings")}
              </Link>
            </NavItem>
            <NavItem className="d-sm-none">
              <Button color="link" onClick={() => Auth.logout()}>
                {t("log_out")}
              </Button>
            </NavItem>
          </>
        )}
      </Translation>
    );
  }
);

export const Availability = ({ availability, players }) => {
  if (!availability) return null;

  let available;
  if (players) {
    available = availability.maxPlayers >= players;
  } else if (availability.maxPlayers === 0) {
    available = false;
  } else {
    available = true;
  }

  return (
    <Translation>
      {t => (
        <small
          className={
            "font-weight-bold mb-0 mt-1 text-uppercase " +
            (available ? "text-primary" : "text-secondary")
          }
        >
          {available ? t("available") : t("not_available")}
        </small>
      )}
    </Translation>
  );
};

export const CartIcon = ({ count = 0, theme = "dark" }) => {
  let textClass = theme === "dark" ? "text-body" : "text-white";
  let iconClass = `fa-layers-counter text-white ${
    count === 0 ? "bg-dark" : "bg-danger"
  }`;
  return (
    <span className={`CartIcon fa-layers fa-fw mr-2 ${textClass}`}>
      <FontAwesomeIcon focusable={false} icon="shopping-basket" />
      <span
        style={{
          fontSize: "2.8rem",
          top: -7,
          right: -7
        }}
        className={iconClass}
      >
        {count}
      </span>
    </span>
  );
};

export const CheckoutAgreement = () => (
  <Translation>
    {t => (
      <AvGroup className="mb-3" check>
        <Label check for="terms">
          <AvInput type="checkbox" name="terms" required />
          <p className="d-inline-block text-body mb-1">
            <Trans i18nKey="terms_agreement_text">
              I agree with the{" "}
              <Link
                to={TERMS_OF_SERVICE_ROUTE.url()}
                target="noopener noreferer"
              >
                terms of service
              </Link>{" "}
              and{" "}
              <Link to={PRIVACY_POLICY_ROUTE.url()} target="noopener noreferer">
                privacy policy
              </Link>{" "}
              by placing this order.
            </Trans>
          </p>
          <AvFeedback>{t("terms_agreement_validation_text")}</AvFeedback>
        </Label>
      </AvGroup>
    )}
  </Translation>
);

export const CheckoutNewsletter = ({ onChange = () => null }) => (
  <Translation>
    {t => (
      <AvGroup className="mb-4" check>
        <Label check>
          <AvInput
            type="checkbox"
            name="newsletter"
            onChange={e => {
              onChange(e.target.checked);
            }}
          />
          {t("newsletter_agree_text")}
        </Label>
      </AvGroup>
    )}
  </Translation>
);

export const CheckoutUserCard = ({ user }) => (
  <Translation>
    {t => (
      <Card className="UserCard mb-3">
        <CardBody className="text-center">
          <FontAwesomeIcon
            icon="user-circle"
            size="2x"
            className="d-block mx-auto text-muted"
          />
          <p className="mb-0 mt-2">
            {user.firstName} {user.lastName}
          </p>
          <small className="d-block mb-2 text-black-50">{user.email}</small>
          <Link to={USER_PROFILE_ROUTE.url()}>{t("edit")}</Link>
        </CardBody>
      </Card>
    )}
  </Translation>
);

export const CheckoutGuest = () => (
  <Translation>
    {t => (
      <div>
        <div className="row">
          <AvGroup className="col-md-6 mb-3">
            <Label for="firstName">{t("first_name")}</Label>
            <AvField
              className="form-control-lg"
              name="firstName"
              id="firstName"
              validate={NAME_VALIDATOR}
            />
          </AvGroup>
          <AvGroup className="col-md-6 mb-3">
            <Label for="lastName">{t("last_name")}</Label>
            <AvField
              className="form-control-lg"
              name="lastName"
              id="lastName"
              validate={NAME_VALIDATOR}
            />
          </AvGroup>
        </div>

        <AvGroup>
          <Label for="email">{t("email")}</Label>
          <AvField
            name="email"
            className="form-control-lg"
            id="email"
            type="text"
            placeholder="you@example.com"
            validate={EMAIL_VALIDATOR}
          />
        </AvGroup>

        <p className="text-black-50 text-right">
          <span>{t("already_have_an_account")}</span>{" "}
          <Link
            to="/signin"
            onClick={e => {
              e.preventDefault();
              EventEmitterClient.emit("LOGIN_SHOW");
            }}
          >
            {t("log_in")}
          </Link>{" "}
        </p>
      </div>
    )}
  </Translation>
);

export const ConnectedCurrentRefinements = ({ onClickHandler }) => {
  const Refinements = connectCurrentRefinements(({ items, refine }) => {
    if (!items.length) return null;

    const attrs = ["city", "state", "country", "areas"];
    const filteredItems = items.filter(item => attrs.includes(item.attribute));

    if (!filteredItems.length) return null;

    return filteredItems.map((item, index) => {
      // convert to singular for areas
      const labelKey = item.attribute === "areas" ? "area" : item.attribute;

      return (
        <Translation key={index}>
          {t => (
            <div className="ais-CurrentRefinements">
              <ul className="ais-CurrentRefinements-list">
                <li className="ais-CurrentRefinements-item">
                  <span className="ais-CurrentRefinements-label">
                    {t(labelKey)}: {item.currentRefinement}
                  </span>
                  <span className="ais-CurrentRefinements-category">
                    <button
                      className="ais-CurrentRefinements-delete"
                      onClick={() => {
                        refine(item.value);
                        onClickHandler(item.attribute);
                      }}
                    >
                      ✕
                    </button>
                  </span>
                </li>
              </ul>
            </div>
          )}
        </Translation>
      );
    });
  });
  return <Refinements />;
};

export const ConnectedMenu = connectMenu(() => null);

export const ConnectedPagination = connectPagination(
  ({ nbPages, currentRefinement }) => (
    <Pagination
      defaultRefinement={3}
      className={nbPages > 1 ? "d-block" : "d-none"}
      showLast
    />
  )
);

const ConnectedPlayersRange = connectRange(() => null);
const ConnectedPriceRange = connectRange(() => null);
const ConnectedRefinementList = connectRefinementList(() => null);

export const CustomAlert = ({ icon = "ban", color = "danger", text }) => {
  return (
    <div className={`CustomAlert d-flex align-items-center ${color}`}>
      <FontAwesomeIcon icon={icon} className="ml-2" style={{ marginTop: -2 }} />
      <span className="ml-3 text-left">{text}</span>
    </div>
  );
};

export const Dots = ({ maxPlayers }) => {
  let dots = [];
  for (let index = 1; index <= 4; index++) {
    let className = index <= maxPlayers ? "dot dot-active" : "dot dot-inactive";
    dots.push(<span key={index} className={className} />);
  }
  return dots;
};

export const FormLogo = ({ src }) => (
  <img
    className="form-logo d-block mx-auto mb-2"
    src={logo}
    alt="Greenfee365"
  />
);

const getInputContainerClasses = showIcon =>
  classNames({
    "icon-input-group": showIcon,
    "icon-input-group-lg": showIcon
  });

export const GolfAreaPill = ({ param = "area", paramValue, text }) => {
  const search = `?${param}=${paramValue.toLowerCase()}`;
  return (
    <Link
      className="btn btn-primary mr-1 mb-1"
      style={{ borderRadius: "2rem" }}
      to={SEARCH_ROUTE.url(search)}
    >
      {text}
    </Link>
  );
};

export const InputEmail = ({
  id,
  name = "email",
  onChange = () => null,
  showIcon = true,
  value = ""
}) => (
  <Translation>
    {t => (
      <AvGroup>
        <div className={getInputContainerClasses(showIcon)}>
          <AvField
            className="form-control-lg"
            id={id}
            name={name}
            onChange={event => {
              onChange(event.target.value);
            }}
            placeholder={t("email")}
            validate={EMAIL_VALIDATOR}
            value={value}
            autoComplete="username"
          />
          {showIcon && (
            <FontAwesomeIcon
              focusable={false}
              size="sm"
              className="text-black-50"
              icon="envelope"
            />
          )}
        </div>
      </AvGroup>
    )}
  </Translation>
);

export const InputFirstName = ({
  id,
  name = "fname",
  showIcon = true,
  value = "",
  onChange = () => null
}) => (
  <Translation>
    {t => (
      <AvGroup>
        <div className={getInputContainerClasses(showIcon)}>
          <AvField
            className="form-control-lg"
            id={id}
            name={name}
            onChange={event => {
              onChange(event.target.value);
            }}
            placeholder={t("first_name")}
            required
            value={value}
            validate={NAME_VALIDATOR}
          />
          {showIcon && (
            <FontAwesomeIcon
              focusable={false}
              size="sm"
              className="text-black-50"
              icon="user"
            />
          )}
        </div>
      </AvGroup>
    )}
  </Translation>
);

export const InputLastName = ({
  id,
  name = "lname",
  onChange = () => null,
  showIcon = true,
  value = ""
}) => (
  <Translation>
    {t => (
      <AvGroup>
        <div className={getInputContainerClasses(showIcon)}>
          <AvField
            className="form-control-lg"
            id={id}
            name={name}
            onChange={event => {
              onChange(event.target.value);
            }}
            placeholder={t("last_name")}
            required
            value={value}
            validate={NAME_VALIDATOR}
          />
          {showIcon && (
            <FontAwesomeIcon
              focusable={false}
              size="sm"
              className="text-black-50"
              icon="user"
            />
          )}
        </div>
      </AvGroup>
    )}
  </Translation>
);

export const InputPassword = ({
  id,
  name = "password",
  onChange = () => null,
  showIcon = true,
  value = ""
}) => (
  <Translation>
    {t => (
      <AvGroup>
        <div className={getInputContainerClasses(showIcon)}>
          <AvField
            className="form-control-lg"
            id={id}
            name={name}
            onChange={event => {
              onChange(event.target.value);
            }}
            placeholder={t("password")}
            type="password"
            value={value}
            validate={PASSWORD_VALIDATOR}
            autoComplete="new-password"
          />
          {showIcon && (
            <FontAwesomeIcon
              focusable={false}
              size="sm"
              className="text-black-50"
              icon="lock"
            />
          )}
        </div>
      </AvGroup>
    )}
  </Translation>
);

export const InputPasswordRepeat = ({
  id,
  name = "passwordRepeat",
  match = "password",
  onChange = () => null,
  value = "",
  showIcon = true
}) => (
  <Translation>
    {t => (
      <AvGroup>
        <div className={getInputContainerClasses(showIcon)}>
          <AvField
            className="form-control-lg"
            id={id}
            name={name}
            onChange={event => {
              onChange(event.target.value);
            }}
            placeholder={t("repeat_password")}
            type="password"
            value={value}
            validate={getRepeatPasswordValidator(match)}
            autoComplete="new-password"
          />
          {showIcon && (
            <FontAwesomeIcon
              focusable={false}
              size="sm"
              className="text-black-50"
              icon="lock"
            />
          )}
        </div>
      </AvGroup>
    )}
  </Translation>
);

export const NearbyClubsBtn = ({
  className = "",
  color = "secondary",
  shadow = false
}) => (
  <Translation>
    {t => (
      <SearchShortcutButton
        className={className}
        color={color}
        icon="location-arrow"
        shadow={shadow}
        text={t("golf_courses_near_you")}
        link={SEARCH_ROUTE.url(`?sortBy=${Algolia.rangeIndex.type}`)}
      />
    )}
  </Translation>
);

export const NoReviews = ({ name }) => (
  <Translation>
    {t => (
      <div className="ClubReviewList mb-5">
        <h3>{t("reviews_title", { club: name })}</h3>
        <em className="text-muted">
          {t("no_reviews_available_for_this_course")}
        </em>
      </div>
    )}
  </Translation>
);

const ConnectedNumHits = connectStats(({ nbHits }) => {
  let number = LocaleHelper.formatNumber(nbHits);
  return (
    <p className="text-black-50 mb-3">
      <Trans i18nKey="num_hits" count={nbHits} values={{ number }}>
        <strong>{{ nbHits }}</strong> clubs matched your search
      </Trans>
    </p>
  );
});
// wrap in withTranslation to trigger rerender on language change
export const NumHits = withTranslation()(ConnectedNumHits);

export const PlayersRange = ({ players }) => {
  return !players ? null : (
    <ConnectedPlayersRange
      attribute="availability.maxPlayers"
      defaultRefinement={{ min: players }}
    />
  );
};

export const Price = ({ price }) => {
  if (!price) return null;
  return (
    <Translation>
      {t => (
        <p className="mb-0 nowrap">
          <span className="lower text-muted mr-1">{t("from")}</span>
          <span className="key-figure">{LocaleHelper.getPrice(price)}</span>
        </p>
      )}
    </Translation>
  );
};

export const PriceRange = ({ price }) => {
  return !price ? null : (
    <ConnectedPriceRange
      attribute="minPrice"
      defaultRefinement={{ max: price }}
    />
  );
};

export const QueryRefinement = ({ query, onClickHandler }) => {
  return !query || query === "" ? null : (
    <Translation>
      {t => (
        <div className="ais-CurrentRefinements">
          <ul className="ais-CurrentRefinements-list">
            <li className="ais-CurrentRefinements-item">
              <span className="ais-CurrentRefinements-label">
                {t("area")}: {query}
              </span>
              <span className="ais-CurrentRefinements-category">
                <button
                  className="ais-CurrentRefinements-delete"
                  onClick={onClickHandler}
                >
                  ✕
                </button>
              </span>
            </li>
          </ul>
        </div>
      )}
    </Translation>
  );
};

export const SearchShortcutButton = withRouter(
  ({ color, icon, link, text, shadow = false, className = "" }) => (
    <Link
      to={link}
      className={`SearchShortcutButton btn btn-${color} ${className} ${
        shadow ? "SearchShortcutButton--shadow" : ""
      }`}
    >
      <FontAwesomeIcon
        icon={icon}
        className="mr-2"
        fixedWidth
        style={{ fontSize: ".75rem" }}
      />
      {text}
    </Link>
  )
);

export const SignupAgreement = () => (
  <Translation>
    {t => (
      <AvGroup className="mb-3" check>
        <Label check for="terms">
          <AvInput
            className="form-control h-auto w-auto"
            type="checkbox"
            name="signupAgreement"
            required
          />
          <p className="d-inline-block text-body mb-1">
            <Trans i18nKey="sign_up_agreement_text">
              I agree with the{" "}
              <Link to={PRIVACY_POLICY_ROUTE.url()} target="noopener noreferer">
                privacy policy
              </Link>{" "}
              by creating this account.
            </Trans>
          </p>
          <AvFeedback>{t("sign_up_agreement_validation_text")}</AvFeedback>
        </Label>
      </AvGroup>
    )}
  </Translation>
);

export const TeeTimeBuggies = withTranslation()(
  ({
    buggies,
    buggyIncluded,
    courseHasBuggy,
    hasBuggyRates,
    enabledValues,
    onClick,
    t
  }) => {
    const BuggyInfo = ({ text, className = "" }) => (
      <p className={`mb-0 key-figure ${className}`}>{text}</p>
    );

    const getBuggyContent = () => {
      if (buggyIncluded)
        return (
          <BuggyInfo text={t("buggy_included")} className="text-success" />
        );
      else if (!courseHasBuggy || !enabledValues.length)
        return (
          <BuggyInfo text={t("buggy_missing_text")} className="text-muted" />
        );
      else if (courseHasBuggy && !hasBuggyRates)
        return (
          <BuggyInfo text={t("buggy_request_text")} className="text-muted" />
        );
      else
        return (
          <TeeTimeOptionButtons
            enabledValues={enabledValues}
            minButton={0}
            maxButton={2}
            value={buggies}
            onClick={onClick}
          />
        );
    };

    return (
      <TeeTimeInfo
        label={t("buggies")}
        className="align-items-start"
        value={getBuggyContent()}
        valueClass="mt-1"
      />
    );
  }
);

export const TeeTimeCancellation = ({ time, cancellationHours }) => {
  const cancellationTime = moment(time).subtract(cancellationHours, "hours");
  const overdue = moment().isAfter(cancellationTime);
  const className = classNames({ "text-danger": overdue, "key-figure": true });

  return (
    <Translation>
      {t => (
        <TeeTimeInfo
          label={t("cancel_by")}
          value={cancellationTime.format("lll")}
          valueClass={className}
        />
      )}
    </Translation>
  );
};

export const TeeTimeInfo = ({
  className = "",
  label,
  value,
  valueClass = ""
}) => (
  <div className={`teetime-summary ${className}`}>
    <small className="teetime-summary-label">{label}</small>
    <div className={valueClass}>{value}</div>
  </div>
);

export const TeeTimePlayers = ({ players, enabledValues, onClick }) => (
  <Translation>
    {t => (
      <TeeTimeInfo
        label={t("players")}
        className="align-items-start"
        value={
          <TeeTimeOptionButtons
            enabledValues={enabledValues}
            minButton={1}
            maxButton={4}
            value={players}
            onClick={onClick}
          />
        }
        valueClass="mt-1"
      />
    )}
  </Translation>
);

export const TimeSpan = ({ availability }) => {
  if (!availability) return null;
  if (!availability.maxPlayers) return null;

  return (
    <small className="text-right text-black-50">
      {moment(availability.firstTime).format("LT") +
        " - " +
        moment(availability.lastTime).format("LT")}
    </small>
  );
};

export const Unauthorized = () => (
  <Translation>
    {t => (
      <div className="Unauthorized container mt-5">
        <h1>{t("unauthorized")}</h1>
        <p>
          <Trans i18nKey="unauthorized_description">
            You are not authorized to view this page. Please{" "}
            <Link
              to="/login"
              onClick={e => {
                e.preventDefault();
                EventEmitterClient.emit("LOGIN_SHOW");
              }}
            >
              log in
            </Link>{" "}
            and try again.
          </Trans>
        </p>
      </div>
    )}
  </Translation>
);

export const Distance = ({ rankingInfo }) => {
  // do not show distance if no geo data
  if (!rankingInfo) return null;
  if (!rankingInfo.matchedGeoLocation) return null;

  const toKm = m => {
    let km = m / 1000;
    let oneDecimal = Math.round(km * 10) / 10;
    return oneDecimal + " km";
  };

  return (
    <div>
      <FontAwesomeIcon
        className="text-secondary"
        icon="map-marker-alt"
        size="sm"
      />
      <small className="mb-0 ml-1">{toKm(rankingInfo.geoDistance)}</small>
    </div>
  );
};
